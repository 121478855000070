import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import KolaboratorSection from './ticket-collaborator/ticket-collaborator.vue'
import PartnerSection from './ticket-partner/ticket-partner.vue'

export default
{
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    KolaboratorSection,
    PartnerSection,
  },
  data() {
    return {
      openCollab: false,
    }
  },
  methods: {
    tabCollab(val) {
      if (val === 1) {
        this.openCollab = true
      } else if (val === 0) {
        this.openCollab = false
      }
    },
  },
}
